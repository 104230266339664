import React, { Suspense, useRef } from 'react';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  CircularProgress,
  Grid,
  LinearProgress,
  Paper
} from '@material-ui/core';
import TopBar from './components/TopBar/TopBar';
import Footer from './components/Footer/Footer';
import { useState } from 'react';
import Api from 'Helper/ApiHandler';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';

var api = new Api();

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: '20rem',
    // paddingRight: '20rem',
    // height: '100%',
    // width: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    // [theme.breakpoints.down('md')]: {
    //     paddingLeft: '10rem',
    //     paddingRight: '10rem',
    // },
    // '@media(max-width: 667px)': {
    //     paddingLeft: '0rem',
    //     paddingRight: '0rem',
    // },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0rem',
      paddingRight: '0rem'
    },
    '@media only screen and (max-height: 600px)': {
      paddingLeft: '0rem',
      paddingRight: '0rem'
    },
    '@media only screen and (max-height: 800px)': {
      paddingLeft: '0rem',
      paddingRight: '0rem'
    }
    // [theme.breakpoints.down('lg')]: {
    //     paddingLeft: '0rem',
    //     paddingRight: '0rem',
    // },
    // backgroundImage: 'url("/images/property2.jpg")',
    // backgroundColor: '#141d2f',
  },

  overlay: {
    width: '100%',
    height: '100vh',
    position: 'absolute',
    overflowX: 'hidden',
    top: 0,
    left: 0,
    // backgroundImage: 'linear-gradient(45deg, rgba(0,0,0,.3) 50%, rgba(0,0,0,.7) 50%)',
    // backdropFilter: 'brightness(0.5)',
    background: 'rgba(0,0,0,0.5)',
    backgroundSize: '3px 3px',
    // [theme.breakpoints.down('sm')]: {
    //     // height: '150vh',
    //     height: '150vh',
    // },
    // '@media only screen and (max-height: 1368px)': {
    //     height: '85vh ',
    // },
    // '@media only screen and (max-height: 1180px)': {
    //     height: '85vh ',
    // },
    // '@media only screen and (max-height: 1024px)': {
    //     height: '95vh ',
    // },
    '@media only screen and (max-height: 915px)': {
      height: '98vh '
    },
    '@media only screen and (max-height: 740px)': {
      height: '75vh '
    },
    '@media only screen and (max-height: 844px)': {
      height: '95vh '
    },
    '@media only screen and (max-height: 896px)': {
      height: '105vh'
    },
    '@media only screen and (max-height: 800px)': {
      height: '95vh'
    },
    '@media only screen and (height: 651px)': {
      height: '150vh '
    },
    '@media only screen and (max-height: 667px)': {
      height: '150vh '
    },
    '@media only screen and (max-height: 600px)': {
      height: '115vh '
    }
    // '@media(max-width: 1919.95px)': {
    //     height: '105vh'
    // },
  },
  bgVideo: {
    position: 'absolute',
    height: '100vh',
    width: '100%',
    zIndex: -1,
    objectFit: 'cover',
    overflow: 'hidden',
    // [theme.breakpoints.down('sm')]: {
    //     height: '150vh',
    //     background: '#2b3b63'
    // },
    // '@media only screen and (max-height: 1368px)': {
    //     height: '85vh ',
    //     background: '#2b3b63'
    // },
    // '@media only screen and (max-height: 1180px)': {
    //     height: '85vh ',
    //     background: '#2b3b63'
    // },
    // '@media only screen and (max-height: 1024px)': {
    //     height: '95vh ',
    //     background: '#2b3b63'
    // },
    '@media only screen and (max-height: 915px)': {
      height: '98vh ',
      background: '#2b3b63'
    },
    '@media only screen and (max-height: 740px)': {
      height: '75vh ',
      background: '#2b3b63'
    },
    '@media only screen and (max-height: 844px)': {
      height: '95vh ',
      background: '#2b3b63'
    },
    '@media only screen and (max-height: 800px)': {
      height: '95vh',
      background: '#2b3b63'
    },
    '@media only screen and (max-height: 896px)': {
      height: '105vh ',
      background: '#2b3b63'
    },
    '@media only screen and (height: 651px)': {
      height: '150vh ',
      background: '#2b3b63'
    },
    '@media only screen and (max-height: 667px)': {
      height: '150vh ',
      background: '#2b3b63'
    },
    '@media only screen and (max-height: 600px)': {
      height: '115vh ',
      background: '#2b3b63'
    }
    // '@media(max-width: 1919.95px)': {
    //     height: '105vh',
    //     background: '#2b3b63'
    // },
  },

  content: {
    height: '100vh',
    width: '100%',
    objectFit: 'cover',
    // [theme.breakpoints.down('sm')]: {
    //     height: '32vh',
    // },
    // '@media only screen and (max-height: 1368px)': {
    //     height: '85vh ',
    // },
    // '@media only screen and (max-height: 1180px)': {
    //     height: '60vh ',
    // },
    // '@media only screen and (max-height: 1024px)': {
    //     height: '40vh ',
    // },

    // '@media only screen and (max-height: 915px)': {
    //     height: '24vh ',
    // },
    // '@media only screen and (max-height: 896px)': {
    //     height: '25vh ',
    // },
    // '@media only screen and (max-height: 844px)': {
    //     height: '25vh ',
    // },
    '@media only screen and (max-height: 800px)': {
      height: '95vh'
    },
    '@media only screen and (max-height: 740px)': {
      height: '75vh '
    },
    '@media only screen and (max-height: 667px)': {
      height: '32vh '
    },
    '@media only screen and (height: 651px)': {
      height: '150vh '
    },
    '@media only screen and (max-height: 600px)': {
      height: '85vh '
    },
    '@media only screen and (width: 1630px)': {
      height: '100vh '
    },
    '@media only screen and (width: 414px)': {
      height: '26vh '
    },
    '@media only screen and (width: 412px)': {
      height: '26vh '
    },
    '@media only screen and (width: 393px)': {
      height: '26vh '
    },
    '@media only screen and (width: 390px)': {
      height: '26vh '
    }
    // '@media(max-width: 1919.95px)': {
    //     height: '25vh'
    // },
  },

  section: {
    height: '100vh',
    textAlign: 'center',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // [theme.breakpoints.down('sm')]: {
    //     height: '150vh',
    // },
    // '@media only screen and (max-height: 1368px)': {
    //     height: '85vh ',
    // },
    // '@media only screen and (max-height: 1180px)': {
    //     height: '85vh ',
    // },
    // '@media only screen and (max-height: 1024px)': {
    //     height: '95vh ',
    // },
    '@media only screen and (max-height: 915px)': {
      height: '98vh '
    },
    '@media only screen and (max-height: 740px)': {
      height: '75vh '
    },
    '@media only screen and (max-height: 844px)': {
      height: '95vh '
    },
    '@media only screen and (max-height: 896px)': {
      height: '105vh '
    },
    '@media only screen and (max-height: 800px)': {
      height: '95vh'
    },
    '@media only screen and (height: 651px)': {
      height: '150vh '
    },
    '@media only screen and (max-height: 667px)': {
      height: '150vh '
    },
    '@media only screen and (max-height: 600px)': {
      height: '115vh '
    }
    // '@media(max-width: 1919.95px)': {
    //     height: '105vh'
    // },
  },
  headline: {
    fontsize: '48px',
    textTransform: 'uppercase',
    backgroundColor: 'transparent'
  },

  blurEdges: {
    backgroundImage: 'radial - gradient(transparent, white)',
    height: '100%',
    width: '100%',
    zIndex: 0,
    position: 'absolute'
  }
}));

const WhiteLabel = props => {
  const { route } = props;

  const classes = useStyles();

  const [videoUrl, setVideoUrl] = useState();
  const [metaTitle, setMetaTitle] = useState();
  const [metaDescription, setMetaDescription] = useState();
  const [metaKeywords, setMetaKeywords] = useState();
  const [mediaType, setMediaType] = useState();
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    mainData();
  }, []);

  const mainData = async () => {
    setLoading(true);
    var url = window.location.host;
    console.log(window.location.host, 'url==>');
    console.log(window.location, 'url==>');
    console.log(
      url
        .split('/')
        .splice(0, 4)
        .join('/')
    );
    const getId = url
      .split('/')
      .splice(0, 4)
      .join('/');

    api
      .get(`whitelabeldomain?domain=${url}`)
      // .get(`whitelabeldomain?domain=http://example12.test:3000`)

      .then(function(result) {
        setVideoUrl(result.data.landingData.banner_video_url);
        setMetaTitle(result.data.landingData.meta_title);
        setMetaDescription(result.data.landingData.meta_description);
        setMetaKeywords(result.data.landingData.meta_keywords);
        setMediaType(result.data.landingData.media_type);

        setLoading(false);
        console.log(false, 'setLoading');
        // if (result.data.landingData.banner_video_url === undefined) {
        //     console.log("undefined");
        // } else {
        // console.log(result.data.landingData.banner_video_url, "response<<<123");
        //     setVideoUrl(result.data.landingData.banner_video_url)
        // }
      })
      .catch(function(error) {
        console.log(error);
      });
  };
  console.log(videoUrl, 'response');

  return (
    <div>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={metaKeywords} />
      </Helmet>
      {loading ? (
        <>
          <div
            style={{
              // padding: '450px',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center',
              marginTop: '60%'
            }}>
            &nbsp;
            <CircularProgress size={20} />
          </div>
        </>
      ) : (
        <>
          <section className={classes.section}>
            <div className={classes.bgVideo}>
              {/* {videoUrl && <video
                                className={classes.content}
                                autoPlay loop muted>
                                <source src={videoUrl}
                                // type='video/mp4' 
                                />
                            </video>} */}

              {mediaType === 'video' ? (
                <>
                  {videoUrl && (
                    <video className={classes.content} autoPlay loop muted>
                      <source src={videoUrl} type="video/mp4" />
                    </video>
                  )}
                </>
              ) : (
                <></>
              )}
              {mediaType === 'Youtube' ? (
                <>
                  <iframe
                    className={classes.content}
                    src={`${videoUrl}?autoplay=1&mute=1`}
                    allowFullScreen
                    autoplay
                    loop
                    muted></iframe>
                </>
              ) : (
                <></>
              )}
              {mediaType === 'Image' ? (
                <>
                  {videoUrl && (
                    <img className={classes.content} src={videoUrl} />
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
          </section>
          <Footer />

          <div className={classes.overlay}>
            <div className={classes.root}>
              <TopBar className={classes.topBar} />
              <div className={classes.container}>
                <main>
                  <Suspense fallback={<LinearProgress />}>
                    {renderRoutes(route.routes)}
                  </Suspense>
                </main>
              </div>
            </div>
            {/* <Footer /> */}
          </div>
        </>
      )}
    </div>
  );
};

WhiteLabel.propTypes = {
  route: PropTypes.object
};

export default WhiteLabel;
