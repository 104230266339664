/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import SuperadminDashboard from 'layouts/SuperadminDashboard';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import RequireDIDTokenOrg from 'RequireDIDTokenOrg';
import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import AdminDashboard from './layouts/AdminDashboard';
import RequireAuthOrg from './RequireAuthOrg';
import RequireAuthSuperAdmin from 'RequireAuthSuperAdmin';
import WhiteLabelLayout from 'layouts/WhiteLabel/WhiteLabel';

const routes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/welcome" />
  },
  {
    path: '/Prototype-Map',
    exact: true,
    component: () => <Redirect to="/USAF" />
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('views/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('views/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },

  // ********************* WhiteLabel *************************
  // {
  //   path: '/landing',
  //   component: WhiteLabelLayout,
  //   routes: [
  //     {
  //       path: '/landing',
  //       exact: true,
  //       component: lazy(() => import('views/WhiteLabel'))
  //     },
  //     {
  //       component: () => <Redirect to="/lending/error-404" />
  //     }
  //   ]
  // },

  // ************************** Superadmin Routes ************************************
  {
    path: '/superadmin',
    component: SuperadminDashboard,
    routes: [
      {
        path: '/superadmin/adminlist',
        exact: true,
        component: RequireAuthSuperAdmin(
          lazy(() => import('views/ManageOrganizationList'))
        )
        // component: lazy(() => import('views/AdminList'))
      },
      {
        path: '/superadmin/payment_transaction',
        exact: true,
        component: RequireAuthSuperAdmin(
          lazy(() => import('views/AdminTransaction'))
        )
        // component: lazy(() => import('views/AdminList'))
      },
      {
        path: '/superadmin/add-admin',
        exact: true,
        component: RequireAuthSuperAdmin(lazy(() => import('views/AddAdmin')))
        // component: RequireAuthOrg(
        //   lazy(() => import('views/WithdrawalRequest'))
        // )
      },
      {
        path: '/superadmin/edit-admin/:id',
        exact: true,
        component: RequireAuthSuperAdmin(lazy(() => import('views/EditAdmin')))
      },
      {
        path: '/superadmin/edit-profile',
        exact: true,
        component: RequireAuthSuperAdmin(
          lazy(() => import('views/EditProfile'))
        )
      },
      {
        path: '/superadmin/change-password',
        exact: true,
        component: RequireAuthSuperAdmin(
          lazy(() => import('views/ChangePasswordSuperadmin'))
        )
      },
      {
        path: '/superadmin/white-label',
        exact: true,
        component: RequireAuthSuperAdmin(
          lazy(() => import('views/ManageWhiteLabelList'))
        )
        // component: lazy(() => import('views/AdminList'))
      },
      {
        path: '/superadmin/trending_status/:id',
        exact: true,
        component: RequireAuthSuperAdmin(
          lazy(() => import('views/ManagetrendingStatus'))
        )
      },
      {
        path: '/superadmin/subscription',
        exact: true,
        component: RequireAuthSuperAdmin(
          lazy(() => import('views/AdminSubscriptionList'))
        )
        // component: lazy(() => import('views/AdminList'))
      },
      {
        path: '/superadmin/add-subscription',
        exact: true,
        component: RequireAuthSuperAdmin(
          lazy(() => import('views/AddSubscriptionPlan'))
        )
        // component: RequireAuthOrg(
        //   lazy(() => import('views/WithdrawalRequest'))
        // )
      },
      {
        path: '/superadmin/edit-subscription/:id',
        exact: true,
        component: RequireAuthSuperAdmin(
          lazy(() => import('views/EditSubscriptionPlan'))
        )
      },

      // *************************************************************
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },

  // ****** Admin routes **************************
  {
    path: '/',
    component: AuthLayout,
    routes: [
      {
        path: '/welcome',
        exact: true,
        component: lazy(() => import('views/OpenmapWelcomeLogin'))
      },
      {
        path: '/forgot-password',
        exact: true,
        component: lazy(() => import('views/ForgotPassword'))
      },
      {
        path: '/superadmin-forgot-password',
        exact: true,
        component: lazy(() => import('views/ForgotPasswordSuperadmin'))
      },
      {
        path: '/admin-reset-password/:token',
        exact: true,
        component: lazy(() => import('views/ResetPasswordAdmin'))
      },
      {
        path: '/superadmin-reset-password/:token',
        exact: true,
        component: lazy(() => import('views/ResetPasswordSuperadmin'))
      },
      {
        path: '/login',
        exact: true,
        component: lazy(() => import('views/OpenmapLogin'))
      },
      {
        path: '/login/superadmin',
        exact: true,
        component: lazy(() => import('views/OpenmapSuperadminLogin'))
      },
      // {
      //   path: '/USAF',
      //   exact: true,
      //   component: lazy(() => import('views/PrototypeMap'))
      // },
      // {
      //   path: '/map/callback/:token',
      //   exact: true,
      //   component: lazy(() => import('views/PrototypeMap'))
      //   // component: RequireDIDTokenOrg(lazy(() => import('views/PrototypeMap')))
      // },
      {
        path: '/map/:token',
        exact: true,
        component: lazy(() => import('views/PrototypeMapLogin'))
      },
      {
        path: '/mapbox-style-guide',
        exact: true,
        component: RequireAuthOrg(lazy(() => import('views/StaticPage')))
      },
      {
        path: '/loginsuccess',
        exact: true,
        component: RequireAuthOrg(
          lazy(() => import('views/OpenmapLoginSuccess'))
        )
      },
      {
        path: '/map-survey/:token',
        exact: true,
        // component: RequireAuthOrg(lazy(() => import('views/OpenmapCreateMap')))
        component: lazy(() =>
          import('views/MapSurveys/components/SurveyForm/surveyForm')
        )
        // component: RequireAuthOrg(
        //   lazy(() =>
        //     import('views/MapSurveys/components/SurveyForm/surveyForm')
        //   )
        // )
      },
      {
        path: '/static-page',
        exact: true,
        // component: RequireAuthOrg(lazy(() => import('views/OpenmapCreateMap')))
        component: RequireAuthOrg(
          lazy(() => import('views/StaticPage/stateicPage'))
        )
      },
      {
        path: '/',
        component: AdminDashboard,
        routes: [
          // *************************  Saymore  ************************
          {
            path: '/change-password',
            exact: true,
            component: RequireAuthOrg(
              lazy(() => import('views/ChangePasswordAdmin'))
            )
          },
          {
            path: '/edit-profile',
            exact: true,
            component: RequireAuthOrg(
              lazy(() => import('views/EditProfileAdmin'))
            )
          },
          {
            path: '/map-dataLayer',
            exact: true,
            // component: RequireAuthOrg(lazy(() => import('views/AddUser')))
            component: RequireAuthOrg(lazy(() => import('views/DataLayer')))
          },
          {
            path: '/create-map',
            exact: true,
            // component: RequireAuthOrg(lazy(() => import('views/OpenmapCreateMap')))
            component: RequireAuthOrg(
              lazy(() => import('views/OpenmapCreateMap'))
            )
          },
          {
            path: '/dashboard',
            exact: true,
            // component: RequireAuthOrg(lazy(() => import('views/OpenmapCreateMap')))
            component: RequireAuthOrg(lazy(() => import('views/Dashboard')))
          },
          {
            path: '/addmap',
            exact: true,
            // component: RequireAuthOrg(lazy(() => import('views/AddUser')))
            component: RequireAuthOrg(lazy(() => import('views/AddMap')))
          },
          {
            path: '/addmap/:slug',
            exact: true,
            // component: RequireAuthOrg(lazy(() => import('views/AddUser')))
            component: RequireAuthOrg(lazy(() => import('views/AddMap')))
          },
          {
            path: '/map-analytics/:slug',
            exact: true,
            // component: RequireAuthOrg(lazy(() => import('views/AddUser')))
            component: RequireAuthOrg(lazy(() => import('views/MapAnalytics')))
          },

          {
            path: '/map-dataLayer',
            exact: true,
            // component: RequireAuthOrg(lazy(() => import('views/AddUser')))
            component: RequireAuthOrg(lazy(() => import('views/DataLayer')))
          },
          {
            path: '/edit/:id',
            exact: true,
            // component: RequireAuthOrg(
            //   lazy(() => import('views/OpenmapEditMap'))
            // )
            component: RequireAuthOrg(
              lazy(() => import('views/OpenmapEditMap'))
            )
          },

          //Datasets***********************************
          // {
          //   path: '/datasets',
          //   exact: true,
          //   component: RequireAuthOrg(lazy(() => import('views/Datasets')))
          // },
          {
            path: '/datasets',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/Datasets')))
          },

          // Notifications******************************
          {
            path: '/notification',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/Notifications')))
            // component: RequireAuthOrg(lazy(() => import('views/ScriptDetails')))
          },

          //Map-Surveys
          {
            path: '/map-survey',
            exact: true,
            component: lazy(() => import('views/MapSurveys'))
            // component: RequireAuthOrg(lazy(() => import('views/MapSurveys')))
            // component: RequireAuthOrg(lazy(() => import('views/AddScript')))
          },
          {
            path: '/map-survey/view/:slug',
            exact: true,
            component: lazy(() => import('views/MapSurveysList'))
            // component: RequireAuthOrg(lazy(() => import('views/MapSurveys')))
            // component: RequireAuthOrg(lazy(() => import('views/AddScript')))
          },
          {
            path: '/survey-analytics/:slug',
            exact: true,
            // component: RequireAuthOrg(lazy(() => import('views/AddUser')))
            component: RequireAuthOrg(
              lazy(() => import('views/SurveyAnalytics'))
            )
          },
          {
            path: '/add-survey',
            exact: true,
            // component: RequireAuthOrg(lazy(() => import('views/AddUser')))
            component: RequireAuthOrg(lazy(() => import('views/AddSurvey')))
          },
          {
            path: '/edit-survey',
            exact: true,
            // component: RequireAuthOrg(lazy(() => import('views/AddUser')))
            component: RequireAuthOrg(lazy(() => import('views/EditSurvey')))
          },
          {
            path: '/survey-detail',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/SurveyDetails')))
            // component: RequireAuthOrg(lazy(() => import('views/AddNAV')))
          },
          {
            path: '/integrations/:id',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/Intregrations')))
          },
          {
            path: '/edit-survey/:id',
            exact: true,
            component: RequireAuthOrg(
              lazy(() =>
                import(
                  'views/SurveyDetails/components/SurveyDetails/EditSurveyPage'
                )
              )
            )
            // component: RequireAuthOrg(lazy(() => import('views/AddNAV')))
          },
          {
            path: '/edit-survey-question/:slug',
            exact: true,
            component: RequireAuthOrg(
              lazy(() => import('views/SurveyFormEdit'))
            )
            // component: RequireAuthOrg(lazy(() => import('views/AddNAV')))
          },
          {
            path: '/multi-choise',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/MultiChoise')))
            // component: RequireAuthOrg(lazy(() => import('views/MultiChoise')))
          },
          {
            path: '/checkbox',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/CheckBoxes')))
            // component: RequireAuthOrg(lazy(() => import('views/MultiChoise')))
          },

          // Manageadmin***********************************
          {
            path: '/manage-manager',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/ManageManager')))

            // component: RequireAuthOrg(
            //   lazy(() => import('views/WithdrawalRequest'))
            // )
          },
          {
            path: '/add-manager',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/AddManager')))
            // component: RequireAuthOrg(
            //   lazy(() => import('views/WithdrawalRequest'))
            // )
          },
          {
            path: '/edit-manager/:id',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/EditManager')))
          },
          {
            path: '/trending_list',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/Trendlist')))
          },

          {
            path: '/add_trending_list',
            exact: true,
            component: RequireAuthOrg(
              lazy(() => import('views/AddTrendingtopic'))
            )
          },
          {
            path: '/recent_covrage',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/RecentCovrage')))
          },

          {
            path: '/add_recent_covrage',
            exact: true,
            component: RequireAuthOrg(
              lazy(() => import('views/AddRecentcoverage'))
            )
            // component: RequireAuthOrg(
            //   lazy(() => import('views/WithdrawalRequest'))
            // )
          },
          {
            path: '/edit_recent_covrage',
            exact: true,
            component: RequireAuthOrg(
              lazy(() => import('views/AddRecentcoverage'))
            )
            // component: RequireAuthOrg(
            //   lazy(() => import('views/WithdrawalRequest'))
            // )
          },

          // White Label****************************************
          {
            path: '/white-label',
            exact: true,
            component: RequireAuthOrg(
              lazy(() => import('views/WhiteLabelDashBoard'))
            )

            // component: RequireAuthOrg(
            //   lazy(() => import('views/WithdrawalRequest'))
            // )
          },
          {
            path: '/subscription',
            exact: true,
            component: RequireAuthOrg(
              lazy(() => import('views/SubscriptionPlan'))
            )
            // component: lazy(() => import('views/AdminList'))
          },
          {
            path: '/add-white-label',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/AddWhiteLabel')))
            // component: RequireAuthOrg(
            //   lazy(() => import('views/WithdrawalRequest'))
            // )
          },
          {
            path: '/edit-white-label/:id',
            exact: true,
            component: RequireAuthOrg(
              lazy(() => import('views/EditWhiteLabel'))
            )
          },

          //users****************************************
          {
            path: '/users',
            exact: true,
            component: RequireAuthOrg(lazy(() => import('views/Users')))
            // component: RequireAuthOrg(
            //   lazy(() => import('views/MobileNotification'))
            // )
          },
          {
            path: '/payment_transaction',
            exact: true,
            component: RequireAuthOrg(
              lazy(() => import('views/VendorTransaction'))
            )
            // component: RequireAuthOrg(
            //   lazy(() => import('views/MobileNotification'))
            // )
          },

          // *************************************************************
          {
            component: () => <Redirect to="/errors/error-404" />
          }
        ]
      }
    ]
  }
];

export default routes;
