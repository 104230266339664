import {
  DescriptionOutlined,
  ExitToAppOutlined,
  GroupOutlined,
  LayersOutlined,
  MapOutlined,
  NotificationsPausedOutlined,
  PermIdentityOutlined,
  PaymentOutlined,
  SettingsOutlined
} from '@material-ui/icons';
import GroupIcon from '@material-ui/icons/Group';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
export default [
  {
    // title: 'MANAGEMENT',
    pages: [
      // -------------------------------------- map --------------------------------------
      {
        title: 'Manage Organization',
        href: '/superadmin/adminlist',
        icon: GroupIcon
      },
      {
        title: 'White Label',
        href: '/superadmin/white-label',
        icon: PermIdentityOutlined
      },
      {
        title: 'Payment Transaction',
        href: '/superadmin/payment_transaction',
        icon: PaymentOutlined
      },
      {
        title: 'Subscription',
        href: '/superadmin/subscription',
        icon: SubscriptionsIcon
      }
      // {
      //   title: 'Ceate Map',
      //   href: '/create-map',
      //   icon: MapOutlined
      // },
      // {
      //   title: 'Datasets',
      //   href: '/datasets',
      //   icon: LayersOutlined
      // },
      // {
      //   title: 'Notification',
      //   href: '/notification',
      //   icon: NotificationsPausedOutlined
      // },
      // {
      //   title: 'Map Surveys',
      //   href: '/map-survey',
      //   icon: DescriptionOutlined
      // },
      // {
      //   title: 'Manage Admin',
      //   href: '/manage-admin',
      //   icon: PermIdentityOutlined
      // },
      // {
      //   title: 'Users',
      //   href: '/users',
      //   icon: GroupOutlined
      // },
      // {
      //   title: 'Settings',
      //   href: '/setting',
      //   icon: SettingsOutlined
      // },
      // {
      //   title: 'Logout',
      //   href: '/login',
      //   icon: ExitToAppOutlined
      // }
    ]
  }
];
