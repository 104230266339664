import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Typography,
  makeStyles
} from '@material-ui/core';
import React from 'react';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { useEffect } from 'react';
import Api from 'Helper/ApiHandler';
import { useState } from 'react';

var api = new Api();

const useStyles = makeStyles(theme => ({
  root: {
    // padding: '20px',
    // marginTop: '100px',
    backgroundImage: `url('/images/Background.png')`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    // backgroundColor: '#141d2f',
    paddingLeft: '20rem',
    paddingRight: '20rem',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '2rem',
      paddingRight: '2rem'
    },
    [theme.breakpoints.down('lg')]: {
      paddingLeft: '2rem',
      paddingRight: '2rem'
    }
  },
  mainDiv: {
    marginTop: '3rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
    // alignItems: 'center'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  },
  trustCentre: {
    color: '#ffffff',
    marginTop: '45px'
  },
  report: {
    color: '#ffffff'
  },
  typodiv: {
    cursor: 'pointer',
    color: '#94A3B8',
    marginTop: '20px',
    '&:hover': {
      color: '#ffffff'
    }
  },
  description: {
    color: '#94A3B8',
    marginTop: '12px',
    fontSize: '12px'
  },
  EnterpriseGrid: {
    paddingLeft: '50px'
  },
  image: {
    height: '30px',
    borderRadius: '5px'
  },
  imagelogo: {
    // height: '1.75rem'
    height: '45px',
    marginBottom: '1.5rem'
  },
  image1: {
    height: '30px',
    borderRadius: '5px',
    paddingLeft: '72px'
  },
  divider: {
    background: '#1e293b',
    marginTop: '75px',
    marginLeft: '19px',
    marginRight: '19px'
  },
  rights: {
    // color: '#7485a1',
    fontSize: '13px',
    fontWeight: 200,
    color: '#FFFFFF',
    opacity: 0.5,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      textWrap: 'nowrap'
    }
  },
  listDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '75px',
    paddingBottom: '30px',
    marginLeft: '19px',
    marginRight: '19px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '8px',
      marginRight: '8px',
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  listDivterm: {
    display: 'flex',
    gap: '24px',
    [theme.breakpoints.down('xs')]: {
      gap: '16px',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: '13px'
    }
  },

  anchor: {
    color: '#94A3B8',
    cursor: 'pointer',
    // marginTop: '20px',
    fontFamily: 'Inter, sans-serif',
    fontSize: '14px',
    // display: 'block',
    '&:hover': {
      color: '#ffffff'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
      textAlign: 'center'
    }
  },
  grid: {
    marginTop: '3rem',
    padding: '27px',
    display: 'flex',
    gap: '50px',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0px'
    },
    '& h6': {
      color: '#F7D239',
      display: 'flex',
      fontFamily: 'Inter, sans-serif',
      fontSize: '12px'
    },
    '& div:nth-child(1)': {
      flexBasis: '30%',
      maxWidth: '50%',
      '@media only screen and (max-height: 667px)': {
        flexBasis: '50%',
        maxWidth: '50%'
      },
      '@media only screen and (max-height: 896px)': {
        flexBasis: '50%',
        maxWidth: '50%'
      }
    },
    '& div:nth-child(2)': {
      flexBasis: '30%',
      maxWidth: '25%',
      '@media only screen and (max-height: 667px)': {
        flexBasis: '50%',
        maxWidth: '50%'
      },
      '@media only screen and (max-height: 896px)': {
        flexBasis: '59%',
        maxWidth: '64%'
      }
    },
    '& div:nth-child(3)': {
      flexBasis: '70%',
      maxWidth: '30%'
    },
    '& div:nth-child(4)': {
      flexBasis: '70%',
      maxWidth: '33.3333%'
    },
    '& a': {
      color: '#ffffff',
      cursor: 'pointer',
      marginTop: '20px',
      fontFamily: 'Inter, sans-serif',
      fontSize: '14px',
      display: 'inline-block', // Changed to inline-block to prevent line breaks
      marginRight: '10px', // Added space between links
      whiteSpace: 'nowrap', // Prevents breaking the text into multiple lines
      '&:hover': {
        color: '#94A3B8'
      }
    },
    '& img': {
      height: '30px',
      borderRadius: '5px',
      marginLeft: '60px'
    },
    '& span': {
      color: '#94A3B8',
      marginTop: '20px',
      fontFamily: 'Inter, sans-serif',
      fontSize: '12px',
      display: 'block',
      lineHeight: '20px'
    }
  },

  rootButton: {
    // padding: '20px',
    color: '#ffffff',
    display: 'flex',
    justifyContent: 'center'
  },
  paperStory: {
    // padding: theme.spacing(2),

    backgroundColor: 'rgba(25,35,54,1)',
    color: '#94a3b8',
    border: '1px solid #334155',
    display: 'flex',
    width: '525px',
    justifyContent: 'space-around',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: '30px',
    height: '40px',
    '&:hover': {
      boxShadow: '0px 0px 10px 10px #496c85',
      color: '#ffffff',
      marginTop: '10px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '342px',
      height: '56px'
    }
  },
  story: {
    fontFamily: 'inter',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500
  },

  arrorIcon: {
    color: '#22c55E',
    fontFamily: 'inter',
    display: 'flex',
    alignItems: 'center',
    fontSize: '19px'
  },
  h5: {
    color: '#22c55E',
    fontFamily: 'inter',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500
  },
  yourStory: {
    display: 'flex'
  },
  linkedInImage: {
    borderRadius: '3px',
    height: '22px',
    cursor: 'pointer',
    '&:hover': {
      background: '#ffffff'
    }
  },
  description1: {
    width: '300px',
    color: '#94A3B8',
    marginTop: '12px',
    fontSize: '12px'
  },
  containerGrid: {
    paddingLeft: '64px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0px'
    }
  },
  acknowledgmentText: {
    maxWidth: '600px',
    fontSize: '15px',
    fontWeight: 350,
    lineHeight: '25.2px',

    color: '#FFFFFF',
    opacity: 0.5,
    fontFamily: 'inter'
  }
}));

const Footer = () => {
  const classes = useStyles();

  const [footerContent, setFooterContent] = useState();
  const [footerButton, setFooterButton] = useState();
  const [linkedLink, setLinkedLink] = useState();
  const [copyrightText, setCopyrightText] = useState();
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    setLoading(true);
    console.log(true, 'setLoading');

    var url = window.location.host;
    console.log(window.location.host, 'url==>');
    console.log(window.location, 'url==>');
    console.log(
      url
        .split('/')
        .splice(0, 4)
        .join('/')
    );
    const getId = url
      .split('/')
      .splice(0, 4)
      .join('/');

    api
      .get(`whitelabeldomain?domain=${url}`)
      // .get(`whitelabeldomain?domain=http://example12.test:3000`)

      .then(function(result) {
        console.log(result.data.landingData.copyright_text, 'response');
        console.log(result.data.landingData, 'response');
        setFooterContent(result.data.landingData.footer_content);
        setFooterButton(result.data.landingData.footer_button);
        setLinkedLink(result.data.landingData.linked_link);
        setCopyrightText(result.data.landingData.copyright_text);

        setLoading(false);
      })
      .catch(function(error) {
        console.log(error);
      });
  }, []);

  const handleLinkClick = () => {
    const url = linkedLink;
    window.open(url, '_blank');
  };

  return (
    <div className={classes.root}>
      {loading ? (
        <>
          &nbsp;
          <CircularProgress size={20} />
        </>
      ) : (
        <>
          <Grid container style={{ justifyContent: 'space-between' }}>
            <Grid item xs={12} sm={6} xl={4} lg={4} md={4}>
              <div className={classes.mainDiv}>
                <div>
                  <img
                    src={
                      'https://devapi.openmaps.pro/uploads/images/map/1695686287_Safespace(light)crop.png'
                    }
                    className={classes.imagelogo}
                  />
                </div>
                <div>
                  <p className={classes.acknowledgmentText}>
                    We acknowledge that the land on which Safespace is located
                    is the traditional territory of First Nations, Métis, Inuit,
                    and all First Peoples of Canada. We honour and respect the
                    history, cultures, and contributions of Indigenous peoples
                    to this land and recognize their ongoing presence and
                    connection to it.
                  </p>
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              xl={4}
              lg={4}
              md={4}
              className={classes.grid}>
              {/* <div
                className={classes.grid}
                dangerouslySetInnerHTML={{
                  __html: footerContent
                }}
              /> */}
              <div>
                <h6>Resources</h6>
                <a href="https://iphcc.ca/safespace-network/">iphcc</a>
                <a href="https://safespacenetwork.ca">Safespace Networks</a>
              </div>
              <div>
                <h6>About</h6>
                <a href="#">Privacy Policy</a>
                <a href="#">Data Policy</a>
                <a href="#">Network</a>
              </div>
              <div>
                <h6>Follow Us</h6>
                <a href="#">Facebook</a>
                <a href="#">Twitter</a>
                <a href="#">Instagram</a>
              </div>

              {/* <div>
                <h6>SafeSpace Networks</h6>
                <span>Copyright 2023</span>
              </div> */}
            </Grid>
          </Grid>

          {/* <Grid container spacing={3} className={classes.grid}>
                        <Grid item xs={6} sm={2}>

                            <Typography variant='h6' className={classes.report}>Reporting</Typography>
                            <Typography className={classes.typodiv}>What can I report?</Typography>
                            <Typography className={classes.typodiv}>How should I report?</Typography>
                            <Typography className={classes.typodiv}>Is it safe to report?</Typography>
                            <Typography variant='h6' className={classes.trustCentre}>Trust Centre</Typography>
                            <Typography className={classes.typodiv}>Privacy Policy</Typography>
                            <Typography className={classes.typodiv}>Terms of Use</Typography>
                        </Grid>
                        <Grid item xs={6} sm={3} >
                            <div className={classes.EnterpriseGrid}>
                                <Typography variant='h6' className={classes.report}>Enterprise</Typography>
                                <Typography className={classes.typodiv}>Respect@Work Legislation</Typography>
                                <Typography className={classes.typodiv}>Reporting Options</Typography>
                                <Typography className={classes.typodiv}>Platform Features</Typography>
                                <Typography className={classes.typodiv}>Onboarding & Support</Typography>
                                <Typography className={classes.typodiv}>Corporate Affairs</Typography>
                                <Typography className={classes.typodiv}>Security & Privacy</Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <img src='/images/aboriginal-flag.webp' className={classes.image} />
                            <Typography className={classes.description}>In the spirit of reconciliation, RespectX acknowledges the Traditional Custodians of the country throughout Australia and their connections to land, sea and community. We pay our respect to their Elders past, present and future, and extend that respect to all Aboriginal and Torres Strait Islander peoples today.</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} >
                            <div className={classes.containerGrid}>
                                <img src='/images/pride-progress-flag.webp' className={classes.image} />
                                <Typography
                                    className={classes.description}
                                >RespectX is a place free from discrimination, where everyone belongs. We respect and celebrate the diversity of our communities, including people of all cultural and faith backgrounds, classes, gender and sexual identities, ages and abilities. We are committed to creating a safe and welcoming space for all.</Typography>
                            </div>
                        </Grid>
                    </Grid> */}
          {/* <Divider className={classes.divider} /> */}
          <div className={classes.listDiv}>
            <Typography className={classes.rights}>{copyrightText}</Typography>
            {/* <img
              className={classes.linkedInImage}
              src="/images/linkedin-square-icon 1.jpg"
              onClick={handleLinkClick}
            /> */}
            <div className={classes.listDivterm}>
              <a
                href="https://iphcc.ca/safespace-network/"
                className={classes.anchor}>
                Terms & Conditions
              </a>
              <a
                href="https://iphcc.ca/safespace-network/"
                className={classes.anchor}>
                Privacy Policy
              </a>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Footer;
