/* eslint-disable react/display-name */
import React, { useState, forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { ListItem, Button, Collapse } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const useStyles = makeStyles(theme => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 10,
    paddingRight: 10
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    '& .MuiButton-root': {
      minWidth: 'auto'
    }
  },
  button: {
    color: 'white',
    // color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%'
  },
  buttonLeafSmall: {
    color: 'white',
    // color: colors.blueGrey[800],
    padding: '10px 16px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      fontWeight: theme.typography.fontWeightMedium
    }
  },

  buttonLeaf: {
    color: 'white',
    // color: colors.blueGrey[800],
    padding: '14px 15px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      fontWeight: theme.typography.fontWeightMedium
    }
  },
  icon: {
    color: 'white',
    // marginRight: '28px',
    // color: theme.palette.icon,
    display: 'flex',
    alignItems: 'center'
    // marginRight: theme.spacing(2)
  },
  iconDrawer: {
    color: 'white',
    // color: theme.palette.icon,
    display: 'flex',
    alignItems: 'center',
    // marginRight: theme.spacing(2)
    marginRight: 0
  },
  expandIcon: {
    marginLeft: 'auto',
    height: 16,
    width: 16
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto'
  },
  active: {
    // color: '#474646',
    color: 'white',
    backgroundColor: '#ffffff33',
    // background: "linear-gradient(261.94deg, #FFD24C 0%, #FFD24C 46.4%, #FFEF33 100%)",
    // color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      // color: '#474646'
      color: 'white'
      // color: theme.palette.primary.main
    }
  },
  titleMainDiv: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    width: '85px'
  },
  titleMainDivSmall: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    width: '72px'
  }
}));

const NavigationListItem = props => {
  const {
    title,
    href,
    depth,
    children,
    icon: Icon,
    className,
    open: openProp,
    label: Label,
    openNav,
    setOpenNav,
    ...rest
  } = props;

  const classes = useStyles();
  const [open, setOpen] = useState(openProp);

  const handleToggle = () => {
    setOpen(open => !open);
  };

  let paddingLeft = 0;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = {
    paddingLeft
  };

  if (children) {
    return (
      <ListItem
        {...rest}
        className={clsx(classes.item, className)}
        disableGutters>
        <Button className={classes.button} onClick={handleToggle} style={style}>
          {Icon && (
            <Icon
              className={`${openNav ? classes.icon : classes.iconDrawer}`}
            />
          )}
          {openNav && title}
          {open ? (
            <ExpandLessIcon className={classes.expandIcon} color="inherit" />
          ) : (
            <ExpandMoreIcon className={classes.expandIcon} color="inherit" />
          )}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  } else {
    return (
      <ListItem
        {...rest}
        className={clsx(classes.itemLeaf, className)}
        disableGutters>
        <Button
          activeClassName={classes.active}
          // className={clsx(classes.buttonLeaf, `depth-${depth}`)}
          className={openNav ? classes.buttonLeaf : classes.buttonLeafSmall}
          component={CustomRouterLink}
          exact
          // style={style}
          to={href}>
          {Icon && (
            <div
              className={`${
                !openNav ? classes.titleMainDiv : classes.titleMainDivSmall
              }`}>
              <Icon
                className={`${openNav ? classes.iconDrawer : classes.icon}`}
              />
              <div style={{ fontSize: '10px' }}>{!openNav && title}</div>
            </div>
          )}
          {openNav && title}
          {Label && (
            <span className={classes.label}>
              <Label />
            </span>
          )}
        </Button>
      </ListItem>
    );
  }
};

NavigationListItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.any,
  label: PropTypes.any,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired
};

NavigationListItem.defaultProps = {
  depth: 0,
  open: false
};

export default NavigationListItem;
