import {
  DescriptionOutlined,
  ExitToAppOutlined,
  GroupOutlined,
  LayersOutlined,
  MapOutlined,
  NotificationsPausedOutlined,
  HomeOutlined,
  PermIdentityOutlined,
  PaymentOutlined,
  SettingsOutlined
} from '@material-ui/icons';
import { IconArrowsShuffle } from '@tabler/icons';
import GroupIcon from '@material-ui/icons/Group';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';

export default [
  {
    // title: 'MANAGEMENT',
    pages: [
      // -------------------------------------- map --------------------------------------
      {
        title: 'Dashboard',
        href: '/dashboard',
        icon: HomeOutlined
      },
      {
        title: 'Create Map',
        href: '/create-map',
        icon: MapOutlined
      },
      {
        title: 'Datasets',
        href: '/datasets',
        icon: LayersOutlined
      },
      {
        title: 'Notification',
        href: '/notification',
        icon: NotificationsPausedOutlined
      },
      {
        title: 'Map Surveys',
        href: '/map-survey',
        icon: DescriptionOutlined
      },
      // {
      //   title: 'Integrations',
      //   href: '/integrations',
      //   icon: IconArrowsShuffle
      // },
      {
        title: 'Manage Manager',
        href: '/manage-manager',
        icon: PermIdentityOutlined
      },
      {
        title: 'Users',
        href: '/users',
        icon: GroupOutlined
      },
      {
        title: 'Payment Transaction',
        href: '/payment_transaction',
        icon: PaymentOutlined
      },
      {
        title: 'White Label',
        href: '/white-label',
        icon: PermIdentityOutlined
      },
      {
        title: 'Subscription',
        href: '/subscription',
        icon: SubscriptionsIcon
      }
      // {
      //   title: 'Settings',
      //   href: '/setting',
      //   icon: SettingsOutlined
      // }
      // {
      //   title: 'Logout',
      //   href: '/login',
      //   icon: ExitToAppOutlined
      // }
    ]
  }
];
