import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import MomentUtils from '@date-io/moment';
import { Provider as StoreProvider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { renderRoutes } from 'react-router-config';

import theme from './theme';
import { configureStore } from './store';
import routes from './routes';
import whiteLabelRoutes from './whiteLabelRoutes';
import {
  ScrollReset,
  GoogleAnalytics,
  CookiesNotification
} from './components';
import './mixins/moment';
import './mixins/validate';
import './mixins/prismjs';
import './mock';
import './assets/scss/index.scss';
import { useState } from 'react';
import { useEffect } from 'react';
import Api from 'Helper/ApiHandler';
import AppDataContext from 'Helper/AppDataContext';
var api = new Api();

const history = createBrowserHistory();
const store = configureStore();

const App = () => {
  const host = window.location.host;
  
  console.log("window host",host)
  // console.log("host",process.env.REACT_APP_URL)
  console.log("REACT_APP_URL_HOST host",process.env.REACT_APP_URL_HOST)
  var host_routes = [];
  
  if(host == process.env.REACT_APP_URL_HOST){
    host_routes = routes;
  }else{
    host_routes = whiteLabelRoutes;
  }

  const [appContext, setAppContext] = useState({});

  const value = { appContext, setAppContext };

  return (
    <StoreProvider store={store}>
      <AppDataContext.Provider value={value}>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Router history={history}>
              <ScrollReset />
              <GoogleAnalytics />
              {/* <CookiesNotification /> */}
              {renderRoutes(host_routes)}
            </Router>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </AppDataContext.Provider>
    </StoreProvider>
  );
};

export default App;
