import React, { Fragment, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Button, Drawer, Paper, Typography } from '@material-ui/core';
import { Hidden } from '@material-ui/core';
import useRouter from 'utils/useRouter';
import { Navigation } from 'components';
import navigationConfig from './navigationConfig';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import AsyncLocalStorage from '@createnextapp/async-local-storage';
import { MenuOutlined } from '@material-ui/icons';
import NavigationSuperadmin from 'components/NavigationSuperadmin';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    overflowY: 'auto',
    backgroundColor: '#1976D2',
    '& .makeStyles-active-69': {
      backgroundColor: '##ffffff99'
    },
    '& .MuiList-padding': {
      paddingBottom: '0px'
    }
  },
  content: {
    // padding: theme.spacing(2),
    // padding: '0 16px',
    '& .MuiButton-label': {
      fontSize: '13px'
    }
  },
  profile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1)
  },
  divider: {
    marginTop: theme.spacing(2)
  },
  navigation: {
    marginTop: theme.spacing(2)
  },
  logout: {
    color: 'white',
    textAlign: 'left',
    '& .MuiButton-label': {
      display: 'flex',
      justifyContent: 'flex-start'
    },
    textTransform: 'capitalize',
    marginLeft: '5px',
    padding: '10px'
  },
  menuContainer: {
    padding: '15px 64px 2px',
    display: 'flex',
    width: '70%',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  logoTitle: {
    fontSize: '25px',
    // marginLeft: '16px',
    color: 'white'
  },
  superadmin: {
    fontSize: '12px',
    textAlign: 'center',
    color: 'white'
  },
}));

const NavBar = props => {
  const {
    openNav,
    setOpenNav,
    openMobile,
    onMobileClose,
    className,
    ...rest
  } = props;

  const classes = useStyles();
  const router = useRouter();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.location.pathname]);

  const removeToken = () => {
    // removeItem
    console.log('logout............');
    AsyncLocalStorage.removeItem('authToken');
    router.history.push('/organization/login');
  };

  const navbarContent = (
    <div className={classes.content}>
      <nav className={classes.navigation}>
        {navigationConfig.map(list => (
          <NavigationSuperadmin
            component="div"
            key={list.title}
            pages={list.pages}
            title={list.title}
            openNav={openNav}
            setOpenNav={setOpenNav}
          />
        ))}
        {/* <Button
          startIcon={<PowerSettingsNewIcon />}
          fullWidth
          className={classes.logout}
          onClick={() => { removeToken() }}
        >
          Logout
        </Button> */}
      </nav>
    </div>
  );

  return (
    <Fragment>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary">
          {/* <div>
            <MenuOutlined style={{ color: 'black' }} />
          </div> */}
          <div {...rest} className={clsx(classes.root, className)}>
            {navbarContent}
          </div>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        {/* <Drawer
          anchor="left"
          onClose={() => setOpen(!open)}
          open={open}
          variant="persistent"
        > */}
        <Paper
          {...rest}
          className={clsx(classes.root, className)}
          elevation={1}
          square>
          <div className={classes.menuContainer}>
            {/* <MenuOutlined onClick={() => setOpenNav(!openNav)} style={{ color: 'white', cursor: 'pointer' }} /> */}
            {/* {openNav && <Typography className={classes.logoTitle}>SafeSpace</Typography>} */}
            <Typography className={classes.logoTitle}>SafeSpace</Typography>
          </div>
          <Typography className={classes.superadmin}>Superadmin</Typography>
          {navbarContent}
        </Paper>
        {/* </Drawer> */}
      </Hidden>
    </Fragment>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
