/* eslint-disable react/no-multi-comp */
import React from 'react';
import { matchPath } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { List, Typography, Button } from '@material-ui/core';

import useRouter from 'utils/useRouter';
import { NavigationListItem } from './components';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import AsyncLocalStorage from '@createnextapp/async-local-storage';
import Api from 'Helper/ApiHandler';
var api = new Api();

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(0),
    '& .MuiTypography-overline': {
      color: 'white',
      opacity: 0.7
    },
    '& .MuiButton-root': {
      fontSize: '14px'
    }
  },
  logout: {
    color: 'white',
    padding: '14px 32px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: 500,
    '&.depth-0': {
      fontWeight: theme.typography.fontWeightMedium
    },
    marginLeft: '3px'
  },
  logoutIcon: {
    width: '40px',
    marginRight: '7px'
  },
  logoutIconSmall: {
    width: '40px'
  },
  logoutSmall: {
    color: 'white',
    padding: '14px 38px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: 500,
    marginLeft: '3px',
    '&.depth-0': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& .MuiButton-label': {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '11px !important',
      alignItems: 'flex-start'
    },
    '& .MuiButton-startIcon': {
      marginRight: '0'
    }
    // '& .makeStyles-content-54 .MuiButton-label': {
    // }
  }
}));

const NavigationList = props => {
  const { pages, ...rest } = props;

  console.log(pages, 'pages---');
  const roleid = localStorage.getItem('roleId');
  let latPages = pages.filter(obj => obj.title != 'Manage Manager');
  let finalPages = [];

  if (roleid == 3) {
    finalPages = latPages;
  } else {
    finalPages = pages;
  }

  const router = useRouter();

  const classes = useStyles();

  return (
    <List>
      {finalPages.reduce(
        (items, page) => reduceChildRoutes({ items, page, ...rest }),
        []
      )}
    </List>
  );
};

NavigationList.propTypes = {
  depth: PropTypes.number,
  pages: PropTypes.array
};

const reduceChildRoutes = props => {
  const { setOpenNav, openNav, router, items, page, depth } = props;

  if (page.children) {
    const open = matchPath(router.location.pathname, {
      path: page.href,
      exact: false
    });

    items.push(
      <NavigationListItem
        depth={depth}
        icon={page.icon}
        key={page.title}
        label={page.label}
        open={Boolean(open)}
        title={page.title}
        setOpenNav={setOpenNav}
        openNav={openNav}>
        <NavigationList
          depth={depth + 1}
          pages={page.children}
          router={router}
        />
      </NavigationListItem>
    );
  } else {
    items.push(
      <NavigationListItem
        depth={depth}
        href={page.href}
        icon={page.icon}
        key={page.title}
        label={page.label}
        title={page.title}
        setOpenNav={setOpenNav}
        openNav={openNav}
      />
    );
  }

  return items;
};

const Navigation = props => {
  const {
    setOpenNav,
    openNav,
    title,
    pages,
    className,
    component: Component,
    ...rest
  } = props;

  const classes = useStyles();
  const router = useRouter();

  const removeToken = () => {
    api
      .get('adminlogout')
      .then(result => {
        if (result.success === 'true') {
          console.log(result, 'result');
          localStorage.clear();
          router.history.push('/login');
        } else {
          // setLoginOpen(false);
          console.log(result, 'error');
        }
      })
      .catch(err => {
        console.log(err, 'error catch');
      });
  };

  return (
    <Component {...rest} className={clsx(classes.root, className)}>
      {title && <Typography variant="overline">{title}</Typography>}
      <NavigationList
        depth={0}
        pages={pages}
        router={router}
        setOpenNav={setOpenNav}
        openNav={openNav}
      />
      {/* <Button
        startIcon={
          <PowerSettingsNewIcon
            className={openNav ? classes.logoutIcon : classes.logoutIconSmall}
          />
        }
        fullWidth
        className={openNav ? classes.logout : classes.logoutSmall}
        onClick={() => {
          removeToken();
        }}>
        Logout
      </Button> */}
    </Component>
  );
};

Navigation.propTypes = {
  className: PropTypes.string,
  component: PropTypes.any,
  pages: PropTypes.array.isRequired,
  title: PropTypes.string
};

Navigation.defaultProps = {
  component: 'nav'
};

export default Navigation;
