import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Link as RouterLink } from 'react-router-dom';
import { Search } from './components';
import AddCategoryModal from '../../components/AddCategoryModal';
import AddSectorModal from 'components/AddSectorModal';
import MobileNotificationModal from 'components/MobileNotificationModal';
import PushNotificationModal from 'components/PushNotificationModal';
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginBottom: '5px',
    // padding: '25px 25px 8px 25px',
    padding: '30px 25px 13px 33px',
    borderRadius: '10px',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  search: {
    flexGrow: 1,
    // [theme.breakpoints.up('lg')]: {
    //   width: "600px",
    // },
    // [theme.breakpoints.down('md')]: {
    //   width: "450px",
    // },
    width: '100%',
    flexBasis: 480
  },
  addButton: {
    paddingRight: '15px',
    padding: '8px',
    height: '35px',
    minWidth: '84px',
    color: '#474646',
    backgroundImage:
      'linear-gradient(261.94deg, #FFD24C 0%, #FFD24C 46.4%, #FFEF33 100%)'
  },
  addmap: {
    background: '#1976D2',
    color: 'white',
    fontSize: '13px',
    // padding: '8px 15px'
  },
  addIcon: {
    marginRight: '3px',
    fontSize: '18px'
  },
  addContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    }
  },
  typoTitle: {
    fontWeight: 'bold',
    fontSize: '16px'
  }
}));

const SearchBar = props => {
  const {
    addUrl,
    pushNotification,
    mobileNotification,
    addSectorModal,
    add,
    onload,
    onFilter,
    addCategoryModal,
    setSessionMessage,
    onSearch,
    search,
    className,
    ...rest
  } = props;

  const classes = useStyles();
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);

  const handleAddCategory = () => {
    setOpen1(true);
  };

  const handleAddSector = () => {
    setOpen2(true);
  };

  const handleMobileNotification = () => {
    setOpen3(true);
  };

  const handlePushNotification = () => {
    setOpen4(true);
  };

  const handleCloseCat = () => {
    setOpen1(false);
  };

  const handleCloseSec = () => {
    setOpen2(false);
  };

  const handleCloseMobileNotification = () => {
    setOpen3(false);
  };

  const handleClosePushNotification = () => {
    setOpen4(false);
  };

  return (
    <Grid
      {...rest}
      className={clsx(classes.root, className)}
      container
      spacing={3}>
      {search ? (
        <Search
          onSearch={onSearch}
          // className={classes.search}
        />
      ) : (
        <Grid item xs={8} sm={8} md={6} lg={5} xl={5}>
          <Typography className={classes.typoTitle}>Maps</Typography>
        </Grid>
      )}
      {/* {add && ( */}
      <Grid
        className={classes.addContainer}
        item
        xs={4}
        sm={4}
        md={6}
        lg={7}
        xl={7}
        // style={{ display: 'flex', justifyContent: 'flex-end' }}
      >
        {/* {addSectorModal && ( */}
        {add && (
          <Button
            className={classes.addmap}
            color="primary"
            size="small"
            variant="contained"
            onClick={handleAddSector}>
            <AddIcon className={classes.addIcon} />
            ADD ADMIN
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

SearchBar.propTypes = {
  className: PropTypes.string,
  onFilter: PropTypes.func,
  onSearch: PropTypes.func
};

export default SearchBar;
