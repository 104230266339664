/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Link, Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { withStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  colors,
  Typography,
  useMediaQuery,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Drawer,
  Badge,
  Box
} from '@material-ui/core';
import axios from 'utils/axios';
import MenuIcon from '@material-ui/icons/Menu';
import MuiAlert from '@material-ui/lab/Alert';

import Avatar from '@material-ui/core/Avatar';
import AsyncLocalStorage from '@createnextapp/async-local-storage';
import navigationConfig from '../NavBar/navigationConfig';
import ProfileDropdown from 'components/ProfileDropdown';
import {
  NotificationsNoneOutlined,
  NotificationsOutlined
} from '@material-ui/icons';
import Api from 'Helper/ApiHandler';
import AppDataContext from 'Helper/AppDataContext';
import { useContext } from 'react';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import useRouter from 'utils/useRouter';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
var api = new Api();

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5'
  }
})(props => (
  <Menu
    style={{ top: '10px' }}
    elevation={2}
    PaperProps={{
      style: {
        // maxHeight: ITEM_HEIGHT * 4.5,
        width: '40ch',
        // top: '60px',
        borderRadius: '10px',
        boxShadow: 'rgb(227 227 227) 0px 0px 15px 7px'
      }
    }}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: '#dddddd',
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white
      }
    }
  }
}))(MenuItem);

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'white',
    boxShadow: 'none',
    '& .MuiToolbar-gutters': {
      [theme.breakpoints.up('sm')]: {
        paddingRight: '24px'
      }
    },
    '& .MuiToolbar-root': {
      margin: '0px 8px'
    }
    // boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)'
  },
  flexGrow: {
    flexGrow: 1
  },
  mainHeader: {
    color: 'black',
    display: 'flex',
    height: '60px',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '79%',
    padding: '15px',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '35px'
    },
    position: 'relative',
    left: '18%',
    // marginTop: '10px',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      left: '0',
      top: '0'
    }
  },
  userName: {
    marginRight: '8px',
    fontWeight: 500
  },
  search: {
    backgroundColor: 'rgba(255,255,255, 0.1)',
    borderRadius: 4,
    flexBasis: 300,
    height: 36,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center'
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: 'inherit'
  },
  searchInput: {
    flexGrow: 1,
    color: 'inherit',
    '& input::placeholder': {
      opacity: 1,
      color: 'inherit'
    }
  },
  searchPopper: {
    zIndex: theme.zIndex.appBar + 100
  },
  searchPopperContent: {
    marginTop: theme.spacing(1)
  },
  trialButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  trialIcon: {
    marginRight: theme.spacing(1)
  },
  notificationsButton: {
    marginLeft: theme.spacing(1)
  },
  notificationsBadge: {
    backgroundColor: colors.orange[600]
  },
  logoutButton: {
    marginLeft: theme.spacing(1)
  },
  logoutIcon: {
    marginRight: theme.spacing(1)
  },
  avtar: {
    cursor: 'pointer',
    width: '35px',
    height: '35px',
    marginRight: '20px'
  },
  textItem: {
    '& .MuiTypography-body1': {
      fontSize: '12px'
    }
  },
  // mainList: {},
  navContainer: {
    // backgroundColor: '#474646',
    // color: 'white'
  },
  profileCOntainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    // width: '195px',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      marginRight: '16px'
    }
  },
  nameContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    margin: '0px 10px'
  },
  notificationLogo: {
    color: 'black',
    cursor: 'pointer'
  },
  logoimg: {
    marginLeft: '100px'
  },
  logoimgSmall: {
    marginLeft: '69px'
  },
  boxSmall: {
    marginLeft: '38px',
    color: '#1976D2'
  },
  badge: {
    '& .MuiBadge-anchorOriginTopRightRectangle': {
      top: '3px',
      right: '3px'
    },
    '& .MuiBadge-colorSecondary': {
      backgroundColor: 'red'
    },
    '& .MuiBadge-badge': {
      minWidth: '5px',
      height: '16px',
      width: '16px'
    }
  },
  listItemContainer: {
    '& .MuiListItemText-root': {
      marginTop: 0,
      marginBottom: 0
    }
  },
  seeAllBtn: {
    '&:hover': {
      backgroundColor: 'white'
    }
  }
}));

const TopBar = props => {
  const { openNav, onOpenNavBarMobile, className, ...rest } = props;
  const roleId = localStorage.getItem('roleId');

  const router = useRouter();
  const classes = useStyles();
  const [userName, setUserName] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [lstNotificationCount, setLstNotificationCount] = useState(0);
  const [isDrawer, setIsDrawer] = useState(false);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [lstNotificationMsg, setLstNotificationMsg] = useState([]);

  const { appContext, setAppContext } = useContext(AppDataContext);

  const matches = useMediaQuery('(max-width:1280px)');

  useEffect(() => {
    api
      .get('admin/allnotification')
      .then(result => {
        if (result.success === 'true') {
          setLstNotificationMsg(result.data.data);
        } else {
          console.error(result, 'error');
        }
        // setLoading(false);
      })
      .catch(err => {
        console.error(err, 'catch error');
        // setLoading(false);
      });
  }, []);

  const handleProfile = () => {
    setOpen(true);
  };

  const handleProfileClose = () => {
    setOpen(false);
  };

  const handleClickNotification = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseNotification = () => {
    setAnchorEl(null);
  };

  const handleClickAllMsg = () => {
    handleCloseNotification();
    router.history.push('/notification');
  };

  const name = localStorage.getItem('userName');
  const profile = localStorage.getItem('profile_picture');

  const handleLogout = e => {
    e.preventDefault();
    localStorage.clear();
    router.history.push('/login');
    api
      .get('adminlogout')
      .then(result => {
        if (result.success === 'true') {
        } else {
          // setLoginOpen(false);
          console.error(result, 'error');
        }
      })
      .catch(err => {
        console.error(err, 'error catch');
      });
  };

  const list = anchor => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom'
      })}
      role="presentation"
      onClick={() => setIsDrawer(false)}
      onKeyDown={() => setIsDrawer(false)}>
      <List>
        {navigationConfig[0].pages.map((obj, index) => {
          if (obj.children) {
            return (
              <>
                <ListItem className={classes.mainList} button key={index}>
                  <ListItemIcon className={classes.listIcon}>
                    <obj.icon />
                  </ListItemIcon>
                  {/* <Link to={obj.href}> */}
                  <ListItemText
                    className={classes.textItem}
                    primary={obj.title}
                  />
                  {/* </Link> */}
                  {/* <Drawer
                    anchor={'top'}
                    open={true}
                    // onClose={toggleDrawer(anchor, false)}
                  >
                    {obj.children &&
                      obj.children.length &&
                      obj.children.map((objChil, index) => {
                        // console.log(obj.children, 'obj.children');
                        return (
                          <ListItem
                            className={classes.mainList}
                            button
                            key={index}>
                            <Link to={objChil.href}>
                              <ListItemText
                                className={classes.textItem}
                                primary={objChil.title}
                              />
                            </Link>
                          </ListItem>
                        );
                      })}
                  </Drawer> */}
                </ListItem>
              </>
            );
          } else {
            return (
              <>
                <ListItem className={classes.mainList} button key={index}>
                  <ListItemIcon className={classes.listIcon}>
                    <obj.icon />
                  </ListItemIcon>
                  <Link to={obj.href}>
                    <ListItemText
                      className={classes.textItem}
                      primary={obj.title}
                    />
                  </Link>
                </ListItem>
              </>
            );
          }
        })}
        <ListItem
          className={classes.mainList}
          button
          onClick={e => handleLogout(e)}>
          <ListItemIcon className={classes.listIcon}>
            <PowerSettingsNewIcon />
          </ListItemIcon>
          {/* <Link to={obj.href}> */}
          <ListItemText className={classes.textItem} primary={'Logout'} />
          {/* </Link> */}
        </ListItem>
      </List>
    </div>
  );

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      // color="primary"
    >
      {/* {matches ? ( */}
      {/* <div className={classes.mainHeader}>
          <MenuIcon onClick={() => setIsDrawer(true)} />
          <Drawer
            className={classes.navContainer}
            anchor={'left'}
            open={isDrawer}
            onClose={() => setIsDrawer(false)}>
            {list('left')}
          </Drawer>
        </div> */}
      {/* ) : ( */}
      <Toolbar>
        {matches ? (
          <div className={classes.mainHeader}>
            <MenuIcon onClick={() => setIsDrawer(true)} />
            <Drawer
              className={classes.navContainer}
              anchor={'left'}
              open={isDrawer}
              onClose={() => setIsDrawer(false)}>
              {list('left')}
            </Drawer>
          </div>
        ) : (
          <>
            <RouterLink to="/dashboard">
              <img
                alt="Logo"
                src={'/images/logos/SafeSpace.svg'}
                className={!openNav ? classes.logoimg : classes.logoimgSmall}
              />
            </RouterLink>
            {/* <Box>
              <Typography variant="h5" className={classes.boxSmall}>
                Robert Hawkins
              </Typography>
              <div style={{ display: 'flex', gap: '4px' }}>
                <img
                  alt="Location"
                  src={'/images/Frame (7).svg'}
                  className={classes.boxSmall}
                />
                <Typography variant="h6">Great Falls, Maryland</Typography>
              </div>
            </Box> */}
          </>
        )}
        <div className={classes.flexGrow} />
        <div className={classes.profileCOntainer}>
          <div>
            <Badge
              className={classes.badge}
              color="secondary"
              badgeContent={appContext.notificationCount}>
              <NotificationsNoneOutlined
                className={classes.notificationLogo}
                onClick={e => handleClickNotification(e)}
              />
            </Badge>
            <StyledMenu
              id="customized-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseNotification}
              // style={{top: "45px",left: "1440px",width: "23%"}}
            >
              <Typography
                variant="h5"
                style={{ margin: '15px', fontSize: '16px' }}>
                Notification
              </Typography>
              <Divider />
              {lstNotificationMsg.length &&
                lstNotificationMsg.slice(0, 5).map((data, ind) => {
                  return (
                    <React.Fragment>
                      <StyledMenuItem
                        className={classes.listItemContainer}
                        style={{ display: 'flow-root' }}>
                        {/* <ListItemIcon>
                      <SendIcon fontSize="small" />
                    </ListItemIcon> */}
                        <ListItemText
                          primary={
                            <Typography
                              type="body2"
                              variant="h6"
                              style={{ fontSize: '12px' }}>
                              {data.title}
                            </Typography>
                          }
                        />
                        <ListItemText
                          primary={
                            <Typography
                              type="body3"
                              style={{
                                whiteSpace: 'nowrap',
                                width: '100%',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                fontSize: '11px'
                              }}>
                              {data.description}
                            </Typography>
                          }
                        />
                      </StyledMenuItem>
                      <Divider />
                    </React.Fragment>
                  );
                })}
              <StyledMenuItem
                className={classes.seeAllBtn}
                style={{ justifyContent: 'center' }}>
                <Typography className={classes.root} variant="h6">
                  <Link
                    href="#"
                    onClick={() => handleClickAllMsg()}
                    style={{ color: '#1976d2' }}>
                    See All
                  </Link>
                </Typography>
              </StyledMenuItem>
            </StyledMenu>
          </div>
          <div className={classes.nameContainer}>
            <Typography
              style={{
                fontSize: '12px',
                fontWeight: 'bold',
                textTransform: 'capitalize',
                width: 'max-content'
              }}>
              {/* Robert Hawkins */}
              {name}
            </Typography>
            <Typography style={{ fontSize: '10px', color: '#000000b8' }}>
              {roleId == 2 ? 'Vendor' : 'Manager'}
            </Typography>
          </div>
          <Avatar
            onClick={handleProfile}
            alt={userName}
            src={profile || ''}
            className={classes.avtar}
          />
        </div>
      </Toolbar>
      {/* )} */}
      {/* <PricingModal
        onClose={handlePricingClose}
        open={pricingModalOpen}
      /> */}
      {/* <NotificationsPopover
        anchorEl={notificationsRef.current}
        notifications={notifications}
        onClose={handleNotificationsClose}
        open={openNotifications}
      /> */}
      {open && (
        <ProfileDropdown
          name={name}
          profile={profile}
          open={open}
          onClose={handleProfileClose}
        />
      )}
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func
};

export default TopBar;
