import { Button, Grid, Paper, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import Api from 'Helper/ApiHandler';
import { useEffect } from 'react';
import { useState } from 'react';
import useRouter from 'utils/useRouter';
import { useLocation } from 'react-router';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';

var api = new Api();

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '30px',
    [theme.breakpoints.down('xs')]: {
      padding: '12px 0px'
    }

    // backgroundImage: 'url("/images/property2.jpg")',
  },
  mainDiv: {
    display: 'flex',
    gap: '44px',
    // justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      gap: '29px !important'
    }
  },
  subDiv: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '&:hover': {
      color: '#ffffff'
    }
  },
  link: {
    cursor: 'pointer',
    color: '#94a3b8',
    fontSize: '.875rem',
    fontWeight: 500,
    // marginLeft: '50px',
    textAlign: 'center',
    display: 'inline-block',
    '&:hover': {
      color: '#ffffff'
    }
  },
  linkMatch: {
    color: 'white'
  },

  signIn: {
    cursor: 'pointer',
    color: '#94a3b8',
    marginRight: '70px',
    textAlign: 'center',
    display: 'flex',
    '&:hover': {
      color: '#ffffff'
    }
  },
  commonFont: {
    color: '#ffffff'
  },
  arrowIcon: {
    color: '#94a3b8',
    '&:hover': {
      color: '#ffffff'
    }
  },
  contactButton: {
    background: '#e2e8f0',
    fontSize: '15px',
    height: '45px',
    '&:hover': {
      boxShadow: '0px 0px 10px 10px #014f86',
      background: '#ffffff'
    }
  },
  image: {
    // height: '1.75rem'
    cursor: 'pointer',
    height: '45px',
    [theme.breakpoints.down('xs')]: {
      height: '30px'
    }
  }
}));

const TopBar = props => {
  const classes = useStyles();
  const router = useRouter();
  const location = useLocation();
  const upurl = location.pathname;
  const { videoUrl } = props;
  const [logo, setLogo] = useState();
  const [trendingbutton, setTrendingbutton] = useState([]);
  const [mapOpenLink, setMapOpenLink] = useState();
  var domainurl = window.location.host;

  useEffect(() => {
    var url = window.location.host;

    const getId = url
      .split('/')
      .splice(0, 4)
      .join('/');

    api
      .get(`whitelabeldomain?domain=${url}`)
      // .get(`whitelabeldomain?domain=http://example12.test:3000`)

      .then(function(result) {
        // console.log(result.data.mapdata, "response===<<<");
        setLogo(result.data.landingData.logo);
        setTrendingbutton(result.data.landingData.category);
        // setTrendingbutton(result.data.landingData.category);
        setMapOpenLink(result.data.mapdata.map_link);
        //   console.log(result.data.landingData, "response<<<123");
        //   console.log(result.data.landingData.banner_video_url, "response<<<123");
        //   setVideoUrl(result.data.landingData.banner_video_url)
        // setDomainUrl(result.data);
      })
      .catch(function(error) {
        console.error(error);
      });
  }, []);

  const mapOpenlogdetail = () => {
    const url = mapOpenLink;
    // const url = 'http://example.test:3000/map/cec55a';
    // window.open(`http://${url}`, '_blank');
    window.open(`http://${url}`, '_blank');
  };

  const mapOpen = async url => {
    const payload = {
      domain: domainurl
      // domain: 'http://example12.test:3000'
    };

    api
      .post(`article/log`, {
        data: payload
      })
      .then(result => {
        if (result.success === true) {
          mapOpenlogdetail();
        } else {
        }
      })
      .catch(err => {});
  };
  const trendingOpen = () => {
    router.history.push('/trending');
  };
  const handleimage = () => {
    router.history.push('/');
  };
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <div className={classes.mainDiv}>
            {/* <div> */}
            {console.log('trendingbutton', trendingbutton)}
            {/* <Typography variant='h2' className={classes.commonFont}>Respect</Typography> */}
            <img src={logo} className={classes.image} onClick={handleimage} />
            {/* <div style={{ display: 'flex', gap: '15px' }}> */}
            {trendingbutton &&
              trendingbutton.length !== 0 &&
              trendingbutton !== null && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    alignContent: 'center',
                    alignItems: 'center'
                  }}>
                  <Typography
                    variant="h6"
                    className={`${classes.link} ${
                      upurl === '/trending' || /^\/t\/[^/]+$/.test(upurl)
                        ? classes.linkMatch
                        : classes.link
                    }`}
                    onClick={trendingOpen}>
                    Trending Now
                  </Typography>
                  {upurl === '/trending' || /^\/t\/[^/]+$/.test(upurl) ? (
                    <div
                      style={{
                        width: '20px',
                        height: '3px',
                        backgroundColor: 'white',
                        color: 'white',
                        borderRadius: '15px'
                      }}></div>
                  ) : null}
                </div>
              )}

            {mapOpenLink ? (
              <Typography
                variant="h6"
                className={classes.link}
                onClick={mapOpen}>
                Map
              </Typography>
            ) : (
              ''
            )}
            {/* </div> */}
            {/* </div> */}
            {/* <div >
                            <Typography variant='h6' className={classes.link}>
                                Map
                            </Typography>
                        </div> */}
            {/* <div >
                            <Typography variant='h6' className={classes.link}>
                                Enterprise
                            </Typography>
                        </div> */}
          </div>
        </Grid>
        {/* <Grid item xs={8}>
                    <div className={classes.subDiv}>
                        <Typography variant='h6' className={classes.signIn}>
                            Sign In <ArrowRightAltIcon />
                        </Typography>
                        <Button variant="contained" className={classes.contactButton}>
                            Contact Us
                        </Button>
                    </div>
                </Grid> */}
        {/* <video className={classes.vidio} autoPlay loop muted>
                    <source src='/images/vidio.mp4' type='video/mp4' />
                </video> */}
      </Grid>
    </div>
  );
};

export default TopBar;
