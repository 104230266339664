/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import WhiteLabelLayout from 'layouts/WhiteLabel/WhiteLabel';
import AuthLayout from './layouts/Auth';

const whiteLabelRoutes = [
  // {
  //     path: '/',
  //     exact: true,
  //     component: () => <Redirect to="/" />
  // },

  // ********************* WhiteLabel *************************

  {
    path: '/map',
    component: AuthLayout,
    routes: [
      {
        path: '/map/:token',
        exact: true,
        component: lazy(() => import('views/PrototypeMapLogin'))
      }
    ]
  },
  {
    path: '/trending',
    component: AuthLayout,
    routes: [
      {
        path: '/trending',
        exact: true,
        component: lazy(() => import('views/Trending'))
      }
    ]
  },

  {
    path: '/t/:slug',
    component: AuthLayout,
    routes: [
      {
        path: '/t/:slug',
        exact: true,
        component: lazy(() => import('views/TrendingDetails'))
      }
    ]
  },
  {
    path: '/',
    component: WhiteLabelLayout,
    routes: [
      {
        path: '/',
        exact: true,
        component: lazy(() => import('views/WhiteLabel'))
      },
      {
        component: () => <Redirect to="/error-404" />
      }
    ]
  }
];

export default whiteLabelRoutes;
